import { apiBaseUrl } from "./core.config";

export const loginAPI = {
    url: `${apiBaseUrl}auth/login`,
    key: "loginAPIKEY",
};

export const getOrdersListAPI = {
    url: `${apiBaseUrl}order`,
    key: "ordersListAPIKey"
}

export const getOrdersItemsListAPI = {
    url: `${apiBaseUrl}order-item`,
    key: "ordersItemsListAPIKey"
}
export const getOrderImageAPI = {
    url: `${apiBaseUrl}order-image`,
    key: "orderImageAPIKey"
}
export const getPackageTypesListAPI = {
    url: `${apiBaseUrl}package-type`,
    key: "packageTypesListAPIKey"
}

export const getPackageCategoryListAPI = {
    url: `${apiBaseUrl}package-category/all`,
    key: "packageCategoryListAPIKey"
}

export const getRoleListAPI = {
    url: `${apiBaseUrl}role`,
    key: "roleListAPIKey"
}

export const getPackageListAPI = {
    url: `${apiBaseUrl}package`,
    key: "packageListAPIKey"
}
export const getPackageAllListAPI = {
    url: `${apiBaseUrl}package/all`,
    key: "packageAllListAPIKey"
}

export const getPackageStatusListAPI = {
    url: `${apiBaseUrl}package-status`,
    key: "packageStatusListAPIKey"
}

export const getManifestListAPI = {
    url: `${apiBaseUrl}manifest`,
    key: "manifestListAPIKey"
}

export const getChannelListAPI = {
    url: `${apiBaseUrl}channel`,
    key: "channelListAPIKey"
}

export const getCityListAPI = {
    url: `${apiBaseUrl}city`,
    key: "cityListAPIKey"
}

export const getDistrictListAPI = {
    url: `${apiBaseUrl}district/all`,
    key: "getDistrictListAPIKey"
}

export const getReasonListAPI = {
    url: `${apiBaseUrl}reason`,
    key: "reasonListAPIKey"
}

export const getUserStaffList = {
    url: `${apiBaseUrl}staff`,
    key: "getUserStaffListAPIKey"
}

export const getUserDriverList = {
    url: `${apiBaseUrl}driver`,
    key: "getUserDriverListAPIKey"
}

export const getTillList = {
    url: `${apiBaseUrl}driver-till-transaction`,
    key: "getTillListAPIKey"
}

export const getAllLocationListAPI = {
    url: `${apiBaseUrl}location/all`,
    key: "getAllLocationListKey"
}

export const createAuthUserAPI = {
    url: `${apiBaseUrl}user`,
    key: "createAuthUserAPIKey"
}

export const getMerchantBranchAllAPI = {
    url: `${apiBaseUrl}merchant-branch/all`,
    key: "getMerchantBranchAllAPIKey"
}

export const authProfileAPI = {
    url: `${apiBaseUrl}auth/me`,
    key: "authProfileKey"
}

export const merchantBranchAPI = {
    url: `${apiBaseUrl}merchant-branch`,
    key: "getMerchantBranchAPIKey"
}

export const merchantBranchContactAPI = {
    url: `${apiBaseUrl}merchant-branch-contact`,
    key: "getMerchantBranchContactAPIKey"
}

export const merchantAPI = {
    url: `${apiBaseUrl}merchant`,
    key: "merchantAPIKey"
}

export const merchantContractAPI = {
    url: `${apiBaseUrl}merchant-contract`,
    key: "getMerchantContractAPIKey"
}

export const forgotPasswordAPI = {
    url: `${apiBaseUrl}user/forgot-password`,
    key: "forgotPasswordAPIKey"
}
export const forgotPasswordOTPEmailAPI = {
    url: `${apiBaseUrl}user/get-pin-email`,
    key: "forgotPasswordOTPEmailAPIKey"
}
export const forgotPasswordOTPSMSAPI = {
    url: `${apiBaseUrl}user/get-pin-sms`,
    key: "forgotPasswordOTPSMSAPIKey"
}
export const forgotPasswordOTPVerifyAPI = {
    url: `${apiBaseUrl}user/verify-pin`,
    key: "forgotPasswordOTPVerifyAPIKey"
}
export const forgotPasswordResetAPI = {
    url: `${apiBaseUrl}user/reset-password`,
    key: "forgotPasswordResetAPIKey"
}

export const getCustomerList = {
    url: `${apiBaseUrl}customer`,
    key: "getCustomerListAPIKey"
}

export const getZoneAPI = {
    url: `${apiBaseUrl}zone`,
    key: "getZoneAPIKey"
}
export const getCityAPI = {
    url: `${apiBaseUrl}city/all`,
    key: "getCityAPIKey"
}

export const runSheetLastAPI = {
    url: `${apiBaseUrl}runsheet-last`,
    key: "runSheetLastAPIKey"
}

export const getFacilityAPI = {
    url: `${apiBaseUrl}facility`,
    key: "getFacilityAPIKey"
}

export const getAllManifestListAPI = {
    url: `${apiBaseUrl}manifest/all`,
    key: "getAllManifestListAPIKey"
}

export const getRoleAPI = {
    url: `${apiBaseUrl}role`,
    key: "getRoleAPIKey"
}

export const facilityContactAPI = {
    url: `${apiBaseUrl}facility-contact`,
    key: "facilityContactAPIKey"
}

export const getLastMileAPI = {
    url: `${apiBaseUrl}route-last`,
    key: "getLastMileAPIKey"
}

export const getLinehaulAPI = {
    url: `${apiBaseUrl}route-line`,
    key: "getLinehaulAPIKey"
}

export const getRouteLineLocationAPI = {
    url: `${apiBaseUrl}route-line-location`,
    key: "getRouteLineLocationAPIKey"
}

export const getRouteLastCityAPI = {
    url: `${apiBaseUrl}route-last-city`,
    key: "getRouteLastCityAPIKey"
}

export const getMerchantAPI = {
    url: `${apiBaseUrl}merchant`,
    key: "getMerchantAPIKey"
}

export const getContractAPI = {
    url: `${apiBaseUrl}merchant-contract`,
    key: "getContractAPIKey"
}

export const getContractWizardAPI = {
    url: `${apiBaseUrl}merchant-contract/wizard`,
    key: "getContractWizardAPIKey"
}

export const runSheetLineAPI = {
    url: `${apiBaseUrl}runsheet-line`,
    key: "runSheetLineAPIKey"
}

export const getAllFacilityAPI = {
    url: `${apiBaseUrl}facility/all`,
    key: "getAllFacilityAPIKey"
}

export const getMerchantContactAPI = {
    url: `${apiBaseUrl}merchant-contact`,
    key: "getMerchantContactAPIKey"
}

export const getMerchantBankAPI = {
    url: `${apiBaseUrl}merchant-bank`,
    key: "getMerchantBankAPIKey"
}

export const getMerchantCategoryAPI = {
    url: `${apiBaseUrl}merchant-category`,
    key: "getMerchantCategoryAPIKey"
}

export const getLocationAPI = {
    url: `${apiBaseUrl}location`,
    key: "getLocationAPIKey"
}

export const runSheetLastLocationAPI = {
    url: `${apiBaseUrl}runsheet-last-location`,
    key: "runSheetLastLocationAPIKey"
}

export const deliveryChargeManagementAllAPI = {
    url: `${apiBaseUrl}additional-charge/all`,
    key: "deliveryChargeManagementAllAPIKey"
}

export const deliveryChargeManagementAPI = {
    url: `${apiBaseUrl}additional-charge`,
    key: "deliveryChargeManagementAPIKey"
}

export const getAllDistrictAPI = {
    url: `${apiBaseUrl}district/all`,
    key: "getAllDistrictAPIKey"
}

export const getAllProvinceAPI = {
    url: `${apiBaseUrl}province/all`,
    key: "getAllProvinceAPIKey"
}

export const getProvinceAPI = {
    url: `${apiBaseUrl}province`,
    key: "getProvinceAPIKey"
}

export const fileUploadAPI = {
    url: `${apiBaseUrl}upload-s3`,
    key: "fileUploadAPIKey"
}

export const facilityRateAPI = {
    url: `${apiBaseUrl}facility-rate`,
    key: "facilityRateAPIKey"
}

export const facilityWeightRateAPI = {
    url: `${apiBaseUrl}facility-weight-rate`,
    key: "facilityWeightRateAPIKey"
}

export const merchantBranchRateAPI = {
    url: `${apiBaseUrl}merchant-branch-rate`,
    key: "merchantBranchRateAPIKey"
}

export const merchantBranchWeightRateAPI = {
    url: `${apiBaseUrl}merchant-branch-weight-rate`,
    key: "merchantBranchWeightRateAPIKey"
}

export const merchantTemplateAPI = {
    url: `${apiBaseUrl}merchant-template`,
    key: "merchantTemplateAPIKey"
}

export const cashOnDeliveryReport = {
    url: `${apiBaseUrl}report/cash-or-card-on-delivery`,
    key: "cashOnDeliveryReportKey"
}

export const reconciliationReportAPI = {
    url: `${apiBaseUrl}report/delivery-reconciliation`,
    key: "reconciliationReportAPIKey"
}

export const packageStatusReportAPI = {
    url: `${apiBaseUrl}report/package-status`,
    key: "packageStatusReportAPIKey"
}

export const packageWiseCostingReportAPI = {
    url: `${apiBaseUrl}report/package-wise-costing`,
    key: "packageWiseCostingReportAPIKey"
}

export const changePasswordAPI = {
    url: `${apiBaseUrl}user/change-password`,
    key: "changePasswordAPIKey"
}

export const bulkPrintAPI = {
    url: `${apiBaseUrl}manifest/collection-code-bulk`,
    key: "bulkPrintAPIKey"
}

export const orderCSVUploadAPI = {
    url: `${apiBaseUrl}upload-file`,
    key: "orderCSVUploadAPIKey"
}

export const cashOnDeliveryReportAPI = {
    url: `${apiBaseUrl}report/cash-or-card-on-delivery`,
    key: "cashOnDeliveryReportAPIKey"
}

export const countryCodeAPI = {
    url: `${apiBaseUrl}country-code`,
    key: "countryCodeAPIKey"
}

export const sageMerchantReportAPI = {
    url: `${apiBaseUrl}report/sage/merchant-invoice`,
    key: "sageMerchantReportAPIKey"
}

export const sageDriverReportAPI = {
    url: `${apiBaseUrl}report/sage/driver-payment`,
    key: "sageDriverReportAPIKey"
}

export const merchantBankAPI = {
    url: `${apiBaseUrl}merchant-bank`,
    key: "merchantBankAPIKey"
}
export const hubSettlementAPI = {
    url: `${apiBaseUrl}hub-settlement`,
    key: "hubSettlementAPIKey"
}

export const getMerchantBankAllAPI = {
    url: `${apiBaseUrl}merchant-bank/all`,
    key: "getMerchantBankAllAPIKey"
}

export const headOfficeSettlementAPI = {
    url: `${apiBaseUrl}head-office-settlement`,
    key: "headOfficeSettlementAPI"
}

export const merchantSettlementReportAPI = {
    url: `${apiBaseUrl}report/merchant-settlement-transaction`,
    key: "merchantSettlementReportAPI"
}

export const hubSettlementReportAPI = {
    url: `${apiBaseUrl}report/hub-settlement-transaction`,
    key: "hubSettlementReportAPI"
}
